import './header.view.scss';
import { NavLink } from 'react-router-dom';

function HeaderView() {
    return (
        <div className="header">
            <div className='header__logo--container'>
                <NavLink to={'/'} className='header__logo-flex'>
                    <div className="header__logo"></div>
                    <div className='header__logo-dot'></div>
                </NavLink>
            </div>

            <div className="header__cover">
                <div className='header__cover-text'>First</div>
                <div className='header__cover-text--small'>
                    international
                    <br />
                    martech festival
                </div>
            </div>

            <div className="header__date">
                <div className='header__date-text'>09/<span>06</span></div>
                <div className='header__date-text-container'>
                    <div className='header__date-text--sm'>2023</div>
                    <div className='header__date-text--gray'>
                        Artarea, Tbilisi
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderView;

import './Partners.scss';

import Next from '../../assets/imgs/partners/next.png';
import Marketer from '../../assets/imgs/partners/marketer.png';
import BusMedia from '../../assets/imgs/partners/busmedia.png';
import Enter from '../../assets/imgs/partners/enter.png';
import ATGE from '../../assets/imgs/partners/at.ge.png';
import Formula from '../../assets/imgs/partners/formula.png';

function MediaPartnersView() {
    return (
        <div className='container'>
            <div className='partner__container'>
                <a href='https://on.ge/next' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Next} alt='IBM' />
                </a>
                <a href='https://www.entrepreneur.com/ka' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Enter} alt='IBM' />
                </a>
                <a href='https://bm.ge/en/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={BusMedia} alt='IBM' />
                </a>
            </div>
            <div className='partner__container partner__container--v2'>
                <a href='https://marketer.ge/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Marketer} alt='IBM' />
                </a>
                <a href='https://at.ge/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={ATGE} alt='atge' />
                </a>
                <a href='https://formula.ge/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Formula} alt='formula' />
                </a>
            </div>
        </div>
    );
}

export default MediaPartnersView;
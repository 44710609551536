import './Marque.scss';
import Marquee from "react-fast-marquee";

function MarqueView() {
    const marque = [
        "Nike",
        "TikTok",
        "Meta",
        "Droga5",
        "Media.Monks",
        "Grey",
        "Google",
        "Meta",
        "Salesforce",
        "ex-Apple"
    ];

    return (
        <Marquee
            speed={70}
            gradient={true}
            gradientColor={[0, 0, 0]}
            pauseOnHover={true}
            className="marque">
            {
                marque.map((item, index) => (
                    <div className="marque__item" key={index}>
                        {item}
                    </div>
                ))
            }
        </Marquee>
    )
}

export default MarqueView;
import './Partners.scss';

import Alta from '../../assets/imgs/partners/alta.png';
import Adjara from '../../assets/imgs/partners/adjara.png';
import Lowenbr from '../../assets/imgs/partners/lowenbr.png';
import Katari from '../../assets/imgs/partners/katari.png';
import GeorgiaTravel from '../../assets/imgs/partners/georigatravel.png';
import Noxton from '../../assets/imgs/partners/noxton.png';
import Evido from '../../assets/imgs/partners/evido.png';
// import DBlock from '../../assets/imgs/partners/dblock.png';
import Mtebi from '../../assets/imgs/partners/mtebi.png';
import Cellfie from '../../assets/imgs/partners/cellfie.png';
import Gita from '../../assets/imgs/partners/gita.png';
import MediaCity from '../../assets/imgs/partners/mediacity.png';
import Brid from '../../assets/imgs/partners/brid.jpg';

function PartnersView() {
    return (
        <div className='container'>
            <div className='partner__container'>
                <a href='https://gita.gov.ge/en/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Gita} alt='IBM' />
                </a>
                <a href='https://kettari.com/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Katari} alt='IBM' />
                </a>
                <a href='https://adjaragroup.com/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Adjara} alt='IBM' />
                </a>
            </div>
            <div className='partner__container partner__container--v2'>
                <a href='https://cellfie.ge/en/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Cellfie} alt='Cellfie' />
                </a>
                <a href='https://alta.ge' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Alta} alt='IBM' />
                </a>
                <a href='https://georgia.travel/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={GeorgiaTravel} alt='IBM' />
                </a>
            </div>
            <div className='partner__container partner__container--v3'>
                <a href='https://www.facebook.com/lowenbrauGE/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Lowenbr} alt='IBM' />
                </a>
                <a href='https://www.evido.me/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Evido} alt='IBM' />
                </a>
                <a href='https://mtebi.com' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Mtebi} alt='IBM' />
                </a>
            </div>
            <div className='partner__container partner__container--v3'>
                <a href='http://mediacity.ge//' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={MediaCity} alt='IBM' />
                </a>
                {/* <a href='https://dblock.com/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={DBlock} alt='IBM' />
                </a> */}

                <a href="https://noxtton.com/ka-GE" rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Noxton} alt='IBM' />
                </a>
                <a href="https://www.borjomi.com/us/" rel="noreferrer" target='_blank' className='partner__item'>
                    <img src="https://cdn.shopify.com/s/files/1/0516/2683/6146/files/Logo_Borjomi_FC_H_1262x.png?v=1614292779" alt='Borjomi' />
                </a>
            </div>
            <div className='partner__container partner__container--v3'>
                <a href="https://www.brid.me/" rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Brid} alt='Brid' />
                </a>
            </div>
        </div>
    );
}

export default PartnersView;
import OksanaImg from '../../assets/imgs/speakers/oksana.png';
import AndyImg from '../../assets/imgs/speakers/andy.png';
import EricImg from '../../assets/imgs/speakers/eric.png';
import AyeshaImg from '../../assets/imgs/speakers/ayesha.png';
import Bernardo from '../../assets/imgs/speakers/Bernardo.png';
import Laura from '../../assets/imgs/speakers/Laura.png';
import Sally from '../../assets/imgs/speakers/sally.png';
import Mariam from '../../assets/imgs/speakers/mariam.png';
import Shota from '../../assets/imgs/speakers/shota.png';
import Jino from '../../assets/imgs/speakers/jino.png';
import Menteshashvili from '../../assets/imgs/speakers/menteshashvili.png';
// import PaulaImg from '../../assets/imgs/speakers/paula.png';
// import PavloImg from '../../assets/imgs/speakers/pavlo.png';
import Orcun from '../../assets/imgs/speakers/orcun.png';
import Kinwura from '../../assets/imgs/speakers/kinwura.png';
import Chkhartishvili from '../../assets/imgs/speakers/givichkha.png';
import Jacek from '../../assets/imgs/speakers/jacek.png';
import Adam from '../../assets/imgs/speakers/adam.png';
import Sandro from '../../assets/imgs/speakers/sandro.png';
import Mdivani from '../../assets/imgs/speakers/mdivani.png';
import Cookle from '../../assets/imgs/speakers/cookle.png';
import Dadiani from '../../assets/imgs/speakers/dadiani.png';
// import Avaliani from '../../assets/imgs/speakers/avaliani.png';
import ChavChav from '../../assets/imgs/speakers/chavchav.png';
import Ruso from '../../assets/imgs/speakers/ruso.png';


import Art1 from '../../assets/imgs/art-1.png';
import Art2 from '../../assets/imgs/art-2.png';
import Art3 from '../../assets/imgs/art-3.png';
import Art4 from '../../assets/imgs/art-4.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';


const SpeakersPage = () => {
    useEffect(() => {
        const boxes = gsap.utils.toArray('.speaker');

        boxes.forEach((box, i) => {
            const anim = gsap.fromTo(box,
                { autoAlpha: 0, y: 50 },
                { duration: 1, autoAlpha: 1, y: 0, stagger: .1 });
            ScrollTrigger.create({
                trigger: box,
                animation: anim,
                toggleActions: 'play none none none',
                once: true,
            });
        });
    }, []);

    // Andy Childs
    const speakers = [
        {
            name: 'OKSANA',
            surname: 'VALENTELIS',
            company: 'Tiktok',
            position: 'Creative director',
            location: 'uk',
            image: OksanaImg,
            art: Art1,
        },
        {
            name: 'ANDY',
            surname: 'CHILDS',
            company: 'META',
            position: 'BUSINESS STRATEGIST, FUTURIST',
            location: 'DE',
            image: AndyImg,
            art: Art2,
        },
        {
            name: 'ERIC',
            surname: 'SHAMLIN',
            company: 'MEDIA.MONKS',
            position: (
                <>
                    EVP. GLOBAL HEAD
                    <br />
                    OF ENTERTAINMENT
                    <br />
                    (2X EMMY WINNER)
                </>
            ),
            location: 'LA',
            image: EricImg,
            art: Art3,
        },
        {
            name: 'AYESHA',
            surname: 'KHAN',
            company: 'NIKE',
            position: 'Narrative & Experience Director',
            location: 'JP',
            image: AyeshaImg,
            art: Art4,
        },
        {
            name: 'Orcun',
            surname: 'Fistik',
            company: 'Salesforce ',
            position: 'Marketing Solutions Regional Lead ',
            location: 'FI',
            image: Orcun,
            art: Art1,
        },

        {
            name: 'Jacek',
            surname: 'Markowski',
            company: 'Google ',
            position: 'Ads Solution Architect',
            location: 'PL',
            image: Jacek,
            art: Art3,
        },

        {
            name: 'Adam ',
            surname: 'Nowakowski',
            company: 'META   ',
            position: 'Creative Agency Partner',
            location: 'PL',
            image: Adam,
            art: Art4,
        },

        {
            name: 'Sandro ',
            surname: 'Javakhishvili',
            company: 'JWT Metro   ',
            position: 'Head of Strategy ',
            location: 'GE',
            image: Sandro,
            art: Art4,
        },

        {
            name: 'Vakhtang',
            surname: 'Mdivani',
            company: 'Promofy.app',
            position: 'Chief Product Officer',
            location: 'GE',
            image: Mdivani,
            art: Art4,
        },


        // {
        //     name: 'PAULA MARIE',
        //     surname: 'KILGARIFF',
        //     company: 'TU DUBLIN',
        //     position: 'INTERNATIONAL LECTURER',
        //     location: 'IR',
        //     image: PaulaImg,
        //     art: Art2,
        // },

        {
            name: 'Bernardo',
            surname: 'González',
            company: 'Droga5',
            position: 'Creative Director',
            location: 'US',
            image: Bernardo,
            art: Art2
        },
        {
            name: 'Laura',
            surname: 'Visco',
            company: 'Grey',
            position: 'CCO',
            location: 'UK',
            image: Laura,
            art: Art1
        },

        // {
        //     name: 'Jacek',
        //     surname: 'Markowski',
        //     company: 'Google',
        //     position: 'Ads Solution Architect',
        //     location: 'PL',
        //     // image: AyeshaImg,
        //     art: Art4
        // },

        {
            name: 'Sali',
            surname: 'Kerkadze',
            company: 'Leavingstone ',
            position: 'Head of Web',
            location: 'GE',
            image: Sally,
            art: Art3
        },
        {
            name: 'Mariam',
            surname: 'Lelashvili',
            company: 'Optio',
            position: 'Chief Product Officer',
            location: 'GE',
            image: Mariam,
            art: Art4
        },

        // {
        //     name: 'Sandro',
        //     surname: 'Javakhishvili',
        //     company: 'JWT Metro',
        //     position: 'Head of Strategy',
        //     location: 'GE',
        //     // image: AyeshaImg,
        //     art: Art3
        // },

        {
            name: 'SHOTA',
            surname: 'CHINCHALADZE',
            company: 'INDEPENDENT',
            position: 'BRAND STRATEGIST',
            location: 'GE',
            image: Shota,
            art: Art2
        },
        {
            name: 'Jino',
            surname: 'Dollini',
            company: 'Terminal ',
            position: 'CEO',
            location: 'GE',
            image: Jino,
            art: Art1
        },


        // {
        //     name: 'Nini',
        //     surname: 'Chachanidze',
        //     company: 'Veli.store ',
        //     position: 'Chief customer experience officer',
        //     location: 'GE',
        //     // image: Jino,
        //     art: Art1
        // },
        // {
        //     name: 'Giorgi',
        //     surname: 'Avaliani',
        //     company: 'Holy Motors ',
        //     position: 'Founder/Creative Director',
        //     location: 'GE',
        //     // image: Jino,
        //     art: Art1
        // },

        // {
        //     name: 'Rusudan',
        //     surname: 'Gigineishvili',
        //     company: 'EVIDO ',
        //     position: 'Country Manager',
        //     location: 'GE',
        //     // image: Jino,
        //     art: Art1
        // },

        {
            name: 'Mariam',
            surname: 'Menteshashvili',
            company: 'PRS IN VIVO ',
            position: 'Insight Analyst',
            location: 'GE',
            image: Menteshashvili,
            art: Art1
        },
        // {
        //     name: 'Giorgi',
        //     surname: 'Giorgadze',
        //     company: 'Skillwill ',
        //     position: 'CEO',
        //     location: 'GE',
        //     // image: Jino,
        //     art: Art1
        // },
        {
            name: 'Giorgi',
            surname: 'Kintsurashvili',
            company: (
                <>Bank of <br /> Georgia</>
            ),
            position: 'Head of Digital Banking Ecosystem',
            location: 'GE',
            image: Kinwura,
            art: Art2
        },
        {
            name: 'Givi',
            surname: 'Chkhartishvili',
            company: 'Hapttic',
            position: 'Project Lead',
            location: 'GE',
            image: Chkhartishvili,
            art: Art1
        },

        {
            name: 'David',
            surname: 'Cockle',
            company: 'Ex Apple',
            position: (<>Consultant <br /> (25+ years at Apple)</>),
            location: 'GE',
            image: Cookle,
            art: Art3
        },

        {
            name: 'Sally',
            surname: 'Dadiani',
            company: 'TBC Bank',
            position: 'Brand Manager at Digital Growth Team',
            location: 'GE',
            image: Dadiani,
            art: Art4
        },
        // {
        //     name: 'Giorgi',
        //     surname: 'Avaliani',
        //     company: 'Holy Motors',
        //     position: 'Founder/Creative Director',
        //     location: 'GE',
        //     image: Avaliani,
        //     art: Art2
        // },
        {
            name: 'Nini',
            surname: 'Chachanidze',
            company: 'Veli.store ',
            position: 'Chief customer experience officer',
            location: 'GE',
            image: ChavChav,
            art: Art3
        },

        // Rusudan Gigineishvili 
        {
            name: 'Rusudan',
            surname: 'Gigineishvili',
            company: 'EVIDO',
            position: 'Country Manager',
            location: 'GE',
            image: Ruso,
            art: Art4
        },


    ]

    return (
        <div>
            <div className="title">Speakers</div>

            <div className='speakers'>
                {
                    speakers.map((speaker, index) => {
                        return (
                            <div className="speaker" key={index}>
                                <div className='speaker__top'>
                                    <div className='speaker__location'>
                                        {speaker.location}
                                    </div>
                                    <div className='speaker__content'>
                                        <div className='speaker__company'>
                                            {speaker.company}
                                        </div>
                                        <div className="speaker__image">
                                            <LazyLoadImage src={speaker.art} alt="Art 1" className='speaker__art' />
                                            <LazyLoadImage src={speaker.image} alt="TikTok Speaker" />
                                        </div>
                                    </div>
                                    <div className='speaker__position'>
                                        {speaker.position}
                                    </div>
                                </div>

                                <div className='speaker__name'>
                                    {speaker.name}
                                    <br />
                                    <div className='speaker__name--right'>
                                        {speaker.surname}
                                    </div>
                                </div>
                            </div >
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SpeakersPage;

import './Creathon.scss';

import React from 'react'
import Alta from '../../assets/imgs/alta.png';

export default function Creathon() {
    const openPopup = (className) => {
        document.querySelector(`.popup--${className}`).classList.add('active');
    }

    const onClose = () => {
        document.querySelector('.popup.active').classList.remove('active');
    }

    return (
        <div className='creathon'>

            {/* IDEA */}
            <div className='popup popup--idea'>
                <div className='popup__container'>
                    <div className='popup__title'>
                        <div>BRIEF - IDEA</div>
                        <div onClick={onClose} className='close'></div>
                    </div>

                    <div className='popup__desc'>
                        <b>Context:</b>
                        <div>
                            If you are here, you already know about Touch.Point - the first International Martech Festival in Tbilisi, Georgia.
                            But the problem is that this event is very new, and most of the marketers feel distanced from it.
                        </div>

                        <b>Challenge:</b>
                        <div>
                            How to make Touch.Point LoveMark for Marketers?
                        </div>

                        <b>Target:</b>
                        <div>
                            Well you name it - You are one of them! From what we see, marketers more than any other group of people are addicted to word of mouth, and are significantly part of the bubble.
                        </div>

                        <b>Deliverables:</b>
                        <div>Communication Ideas to raise awareness and image of Touch.Point</div>

                        <b>AI Tools you can use for this brief:</b>

                        <br />

                        <div className='logos'>
                            <a href="https://bard.google.com/">
                                <img src="https://1000logos.net/wp-content/uploads/2023/05/Bard-AI-Logo.jpg" alt="bard" />
                            </a>

                            <a href="https://chat.openai.com/">
                                <img src="https://www.androidheadlines.com/wp-content/uploads/2023/03/GPT-4-logo.jpg" alt="gpt4" />
                            </a>

                            <a href="https://app.storylab.ai/">
                                <img src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F10729058b7bb0ad5ea054c9280321112.cdn.bubble.io%2Ff1664726269638x199317263898099550%2Fstorylab-ai-logo.jpeg?w=256&h=75&auto=compress&fit=crop&dpr=2" alt="main logo" />
                            </a>
                        </div>

                        <b>Deadline:</b>
                        <div>Submission deadline is until 4PM. </div>

                        Good Luck to you!
                    </div>
                </div>
            </div>

            {/* image */}
            <div className='popup popup--image'>
                <div className='popup__container'>
                    <div className='popup__title'>
                        <div>BRIEF - IMAGE</div>
                        <div onClick={onClose} className='close'></div>
                    </div>

                    <div className='popup__desc'>
                        <b>Context:</b>
                        <div>If you are here, you already know about Touch.Point - the first International Martech Festival in Tbilisi, Georgia.</div>
                        <div>But the problem is that this event is very new, and most of the marketers feel distanced from it.</div>

                        <b>Challenge:</b>
                        <div>Create disruptive Poster for Touch.Point 2024 which will demonstrate the character and tone of the brand</div>

                        <b>Keywords:</b>
                        <div>Bold, futuristic, Technological, Innovative, Festive, Touch, Connection, Sharing, Exchanging</div>

                        <b>Target:</b>
                        <div>Well you name it - You are one of them! From what we see, marketers more than any other group of people are addicted to word of mouth, and are significantly part of the bubble.</div>

                        <b>Deliverables:</b>
                        <div>Poster of the next Touch.Point event!</div>

                        <b>AI Tools you can use for this brief:</b>


                        <div className='logos'>
                            <a href='https://www.midjourney.com/home/?callbackUrl=%2Fapp%2F'>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Midjourney_Emblem.png/640px-Midjourney_Emblem.png" alt="bard" />
                            </a>

                            <a href="https://openai.com/dall-e-2/">
                                <img src="https://yt3.googleusercontent.com/UqT_vCkJIn1P2fH1pchr6lbe3xeEekY61h4bUpJkVuityqKOEtUYcNy3pLiJ5OKdj4uKA81FWE8=s900-c-k-c0x00ffffff-no-rj" alt="gpt4" />
                            </a>

                            <a href="https://stability.ai/">
                                <img src="https://media.licdn.com/dms/image/C4E0BAQFCiLNVtPU93g/company-logo_200_200/0/1680087340382?e=2147483647&v=beta&t=rslzU2xo7hRJfWk0tqe79Kae17Zjw-qKKxjPG0U6Lq0" alt="ai tool" />
                            </a>
                        </div>

                        <b>Deadline:</b>
                        <div>Submission deadline is until 4PM.</div>
                    </div>
                </div>
            </div>

            {/* VIDEO */}
            <div className='popup popup--video'>
                <div className='popup__container'>
                    <div className='popup__title'>
                        <div>BRIEF - VIDEO</div>
                        <div onClick={onClose} className='close'></div>
                    </div>

                    <div className='popup__desc'>
                        <b>Context:</b>
                        <div>If you are here, you already know about Touch.Point - the first International Martech Festival in Tbilisi, Georgia.</div>
                        <div>But the problem is that this event is very new, and most of the marketers feel distanced from it.</div>

                        <b>Challenge:</b>
                        <div>Create short video ad for  Touch.Point 2024 which will demonstrate the character and tone of the brand</div>

                        <b>Tone of the Voice:</b>
                        <ul>
                            <li>Bold - never uses useless words</li>
                            <li>Curious - because that’s the source of the creativity</li>
                            <li>Open Minded - always ready to turn the world around himself upside down</li>
                            <li>Professional - Always knows what he is saying</li>
                        </ul>


                        <b>Visual Feel can be:</b>
                        <ul>
                            <li>Futuristic</li>
                            <li>Cinematic</li>
                            <li>Documentalistic</li>
                            <li>Abstract - no concrete shapes, no concrete storytelling</li>
                        </ul>

                        <b>Target:</b>
                        <div>Well you name it - You are one of them! From what we see, marketers more than any other group of people are addicted to word of mouth, and are significantly part of the bubble.
                        </div>
                        <b>Deliverables:</b>
                        <div>Manifesto of Touch.Point</div>

                        <b>AI Tools you can use for this brief:</b>

                        <div className='logos'>
                            <a href='https://fliki.ai/'>
                                <img src="https://static.gameloop.com/img/ed4a17f16a4790570e28d8982c92707b.png?imageMogr2/thumbnail/172.8x172.8/format/webp" alt="bard" />
                            </a>

                            <a href="https://www.synthesia.io/">
                                <img src="https://pbs.twimg.com/profile_images/1453332220890976258/qthSiQJT_400x400.jpg" alt="gpt4" />
                            </a>

                            <a href="https://www.descript.com//">
                                <img src="https://pbs.twimg.com/profile_images/1019643227672961024/VzsNrztT_400x400.jpg" alt="ai tool" />
                            </a>
                        </div>

                        <b>Deadline:</b>
                        <div>Submission deadline is today until 4PM. </div>

                        Good Luck to you!
                    </div>
                </div>
            </div>

            {/* Copy */}
            <div className='popup popup--copy'>
                <div className='popup__container'>
                    <div className='popup__title'>
                        <div>BRIEF - COPY</div>
                        <div onClick={onClose} className='close'></div>
                    </div>

                    <div className='popup__desc'>
                        <b>Context:</b>
                        <div>If you are here, you already know about Touch.Point - the first International Martech Festival in Tbilisi, Georgia.</div>
                        <div>But the problem is that this event is very new, and most of the marketers feel distanced from it.</div>

                        <b>Challenge:</b>
                        <div>Create insightful, strong copy for Touch.Point 2024 which will demonstrate the character and tone of the brand</div>

                        <b>Tone of the Voice:</b>
                        <ul>
                            <li>Bold - never uses useless words</li>
                            <li>Curious - because that’s the source of the creativity</li>
                            <li>Open Minded - always ready to turn the world around himself upside down</li>
                            <li>Professional - Always knows what he is saying</li>
                        </ul>

                        <b>Target:</b>
                        <div>Well you name it - You are one of them! From what we see, marketers more than any other group of people are addicted to word of mouth, and are significantly part of the bubble.</div>

                        <b>Deliverables:</b>
                        <div>Manifesto of Touch.Point</div>

                        <b>AI Tools you can use for this brief:</b>

                        <div className='logos'>
                            <a href='https://app.copysmith.ai/signup/#a_aid=brunodirect/'>
                                <img src="https://ainewsbase.com/wp-content/uploads/2023/01/copysmith-logo-25-1024x1024.png" alt="bard" />
                            </a>

                            <a href="https://paragraphai.com//">
                                <img src="https://paragraphai.com/wp-content/uploads/2022/10/Purple-background.png" alt="gpt4" />
                            </a>

                            <a href="https://rytr.me/?via=millionify&gclid=CjwKCAjw-IWkBhBTEiwA2exyO3RCJuIMNYEptcorq4dR7YqN62CsbC29m9r6i9wqWSdQmBaJrCMyjxoCwREQAvD_BwE/">
                                <img src="https://storage.googleapis.com/rytr-me/public/image/logo.svg" alt="ai tool" />
                            </a>
                        </div>

                        <b>Deadline:</b>
                        <div>Submission deadline is today until 4PM. </div>

                        Good Luck to you!
                    </div>
                </div>
            </div>

            <div className='creathon__title'>
                Welcome to Touch.Point
                <br />
                AI Creathon
            </div>
            <p className='creathon__desc'>
                The reason you are here is your curious mind which is very much
                <br />
                appreciated at Touch.Point!
                <br />
                That’s why we have a new game for you called AI Creathon?
            </p>

            <div>
                <div className='creathon__by'>
                    <div className='creathon__by-title'>In partnership with</div>
                    <img src={Alta} alt='alta' />
                </div>
            </div>

            <div className='brief'>
                <div className='brief__title'>So what is AI Creathon?</div>

                <div className='brief__desc'>
                    We have selected 4 categories of the briefs:
                    <br />
                    And for each category the most relevant AI tool
                    <br />
                    which will help you to solve this brief.
                </div>

                <div className='brief__list'>

                    <div className='brief__list-item'>
                        <div className='brief__list-item-title'>Idea</div>

                        <div className='brief__list-item-btn' onClick={() => openPopup('idea')}>open brief</div>
                    </div>

                    <div className='brief__list-item'>
                        <div className='brief__list-item-title'>copy</div>

                        <div className='brief__list-item-btn' onClick={() => openPopup('copy')}>open brief</div>
                    </div>

                    <div className='brief__list-item'>
                        <div className='brief__list-item-title'>image</div>

                        <div className='brief__list-item-btn' onClick={() => openPopup('image')}>open brief</div>
                    </div>

                    <div className='brief__list-item'>
                        <div className='brief__list-item-title'>video</div>

                        <div className='brief__list-item-btn' onClick={() => openPopup('video')}>open brief</div>
                    </div>

                </div>

                <div className='brief__faq-title'>What is the deadline? </div>

                <div className='brief__faq-error'>submission deadline is today until 4pm.. </div>

                <div className='brief__faq-desc'>
                    We will recommend not more than 30 minutes for this task.
                    <br />
                    That’s the idea of the AI - to keep it simple.
                </div>

                <div className='brief__faq'>

                    <div className='brief__faq-title'>Why should you do it? </div>

                    <div className='brief__faq-desc'>
                        1. First of all, out of curiosity - which you have as we stated it from the beginning
                    </div>

                    <div className='brief__faq-desc'>
                        2. AI Tools can be really very useful and we want to test it on your own skin
                    </div>

                    <div className='brief__faq-desc'>
                        3. There is a PRIZE for the winners
                    </div>

                </div>

                <div className='brief__faq-price'>Lenovo Tab P11 2nd Gen 11.5" <br /> (6GB/128GB) LTE - Storm Grey. </div>

                <a rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSd_iqtYoi647lgRVIpND-1PCWvA6iULWMSJNq49MDgrsZuG1A/viewform" target='_blank' className='brief__faq-btn'>submit form</a>
            </div>
        </div>
    )
}

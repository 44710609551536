import { useEffect } from "react";
import AgendaItem from "../../components/AgendaItem/AgendaItem";
import AgendaTabs from "../../components/AgendaTabs/AgendaTabs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Jacek from '../../assets/imgs/speakers/jacek.png';

function WorkShops() {
    useEffect(() => {
        const boxes = gsap.utils.toArray('.agendaItem');

        boxes.forEach((box, i) => {
            const anim = gsap.fromTo(box, { autoAlpha: 0, y: 50 }, { duration: 1, autoAlpha: 1, y: 0 });
            ScrollTrigger.create({
                trigger: box,
                animation: anim,
                toggleActions: 'play none none none',
                once: true,
            });
        });
    }, []);

    const workshops = [
        {
            time: "12:00 - 13:00",
            title: (
                <>
                    Data Driven Creatives <br /> <a href="https://docs.google.com/forms/d/e/1FAIpQLSd3raDEom9pWhhRsSzZkxoG8xfs0Cfc3VLV55KtRx1xUzaNLQ/viewform" target="_blank" rel="noreferrer">Register</a>
                </>
            ),
            person: "Jacek Markowski",
            position: "Ads Solution Architect",
            company: 'Google ',
            image: Jacek,
        },
        {
            time: "14:00 - 15:00",
            title: (
                <>
                    Dynamic Videos With Ads Creative Studio
                    <br />
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSftdsgJVnIVR2EUZ5_x4QvtYxginxfT2ueJt7DhnqxwBuoQ4Q/viewform" target="_blank" rel="noreferrer">Register</a>
                </>
            ),
            person: "Jacek Markowski",
            position: "Ads Solution Architect",
            company: 'Google ',
            image: Jacek,
        },
    ]
    return (
        <div>
            <AgendaTabs />

            <div className="content container">
                {
                    workshops.map((item, index) => {
                        const randomArt = Math.floor(Math.random() * (4 - 1 + 1)) + 1;

                        return (
                            <AgendaItem
                                randomArtClassName={`agendaItem__art-${randomArt}`}
                                key={index}
                                time={item.time}
                                title={item.title}
                                description={item.description}
                                person={item.person}
                                position={item.position}
                                image={item.image}
                                images={item.images}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default WorkShops;
import InfoComponent from "../components/Info/Info.component";
import SpeakerComponent from "../components/Speaker/Speaker.component";
import CoverView from "../views/Cover/Cover.view";
import MarqueView from "../views/Marque/Marque.view";
import TicketsView from "../views/Tickets/Tickets";
import TopicsView from "../views/Topics/Topics.view";
import PartnersView from "../views/Partners/Partners";
import MediaPartnersView from "../views/Partners/MediaPartners";
import CommunityPartners from "../views/Partners/CommunityPartners";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { gsap } from "gsap";
// import AICreathon from '../assets/imgs/aicreathon.png';

function Landing() {

    // const closeMiniPopup = () => {
    //     document.querySelector(".miniPopup").style.display = "none";
    // }

    useEffect(() => {
        // Info Component
        gsap.fromTo(".info__container .info",
            { autoAlpha: 0, y: 50 },
            {
                duration: 1,
                autoAlpha: 1,
                y: 0,
                stagger: 0.2,
                scrollTrigger: {
                    trigger: ".info__container",
                    start: "top 80%",
                    end: "bottom 20%",
                }
            });

        // Speaker Component
        const speakerArray = gsap.utils.toArray(".speakers .speaker")
        speakerArray.forEach((speaker) => {
            gsap.fromTo(speaker,
                { autoAlpha: 0, y: 50 },
                {
                    duration: 1,
                    autoAlpha: 1,
                    y: 0,
                    stagger: 0.2,
                    scrollTrigger: {
                        trigger: speaker,
                        start: "top 80%",
                        end: "bottom 20%",
                    }
                });
        })

        // Tickets Component
        gsap.fromTo(".tickets .ticketsCard",
            { autoAlpha: 0, y: 50 },
            {
                duration: 1,
                autoAlpha: 1,
                y: 0,
                stagger: 0.2,
                scrollTrigger: {
                    trigger: ".tickets",
                    start: "top 80%",
                    end: "bottom 20%",
                }
            });

        // Partners Component
        const partners = gsap.utils.toArray(".partner__container")
        partners.forEach((partner) => {
            gsap.fromTo(partner.querySelectorAll(".partner__item"),
                { autoAlpha: 0, y: 50 },
                {
                    duration: 1,
                    autoAlpha: 1,
                    y: 0,
                    stagger: 0.2,
                    scrollTrigger: {
                        trigger: partner,
                        start: "top 80%",
                        end: "bottom 20%",
                    }
                });
        })
    }, [])

    return (
        <div className="App">

            {/* <div className="miniPopup">
                <div className="miniPopup__container">
                    <img src={AICreathon} alt="AICreathon" className="aicreathon" />
                    <NavLink to="/creathon" className="miniPopup__seemore">see more</NavLink>
                    <div className="miniPopup-close" onClick={closeMiniPopup}></div>
                </div>
            </div> */}

            {/* <NavLink to={'/creathon'} className="creation-btn">CREATION</NavLink> */}

            <CoverView />

            <div className="info__container">
                <InfoComponent text="Attendees" count={"1000+"} />
                <InfoComponent text="Speakers" count={"20+"} />
                <InfoComponent text="Countries" count={"10+"} />
            </div>

            <MarqueView />

            <div className="title">Lineup</div>

            <SpeakerComponent />

            <NavLink to="/speakers" className="title-underlined title">view all</NavLink>

            <div className="title">Topics</div>

            <TopicsView />

            <div className="title">Tickets</div>

            <TicketsView />

            <div className="title">Partners</div>

            <PartnersView />

            <div className="title">Media Partners</div>

            <MediaPartnersView />

            <div className="title">COMMuNITY partners</div>

            <CommunityPartners />
        </div>
    );
}

export default Landing;

import React from 'react';

export const ViewFinder = () => (
    <>
        <svg
            width="50px"
            viewBox="0 0 100 100"
            style={{

                top: 0,
                left: '50%',
                zIndex: 1,
                boxSizing: 'border-box',
                // border: '50px solid rgba(0, 0, 0, 0.3)',
                // border: '15vw solid rgb(0, 0, 0)',
                position: 'absolute',
                transform: 'translateX(-50%)',
                width: '100vh',
                height: '100vh',

                border: '20vw solid rgba(0,0,0,0.75)',
                'borderRightWidth': '40vh',
                'borderBottomWidth': '40vh',
                'borderTopWidth': '40vh',
                'borderLeftWidth': '40vh'
            }}
        >
            <path
                fill="none"
                d="M13,0 L0,0 L0,13"
                stroke="rgba(255, 255, 255, 1)"
                strokeWidth="5"
            />
            <path
                fill="none"
                d="M0,87 L0,100 L13,100"
                stroke="rgba(255, 255, 255, 1)"
                strokeWidth="5"
            />
            <path
                fill="none"
                d="M87,100 L100,100 L100,87"
                stroke="rgba(255, 255, 255, 1)"
                strokeWidth="5"
            />
            <path
                fill="none"
                d="M100,13 L100,0 87,0"
                stroke="rgba(255, 255, 255, 1)"
                strokeWidth="5"
            />
        </svg>
    </>
);
import './Topics.scss'

function TopicsView() {
    return (
        <>
            <div className="topic__container">
                <div className="topic__card">
                    <div className="topic__card__title">
                        Data Driven
                        <br />
                        Marketing
                    </div>
                </div>
                <div className="topic__card">
                    <div className="topic__card__title">
                        Marketing &
                        <br />
                        Technology
                    </div>
                </div>
                <div className="topic__card">
                    <div className="topic__card__title">
                        Marketing in
                        <br />
                        Metaverse
                    </div>
                </div>
            </div>

            <div className="topic__container topic__container--v2">
                <div className="topic__card">
                    <div className="topic__card__title">
                        Consumer
                        <br />
                        Behaviour
                    </div>
                </div>
                <div className="topic__card">
                    <div className="topic__card__title">
                        Digital Content
                        <br />
                        & Storytelling
                    </div>
                </div>
                <div className="topic__card">
                    <div className="topic__card__title">
                        Brand &
                        <br />
                        Culture
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopicsView;
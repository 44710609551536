import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Landing from './Pages/Landing.js';
import HeaderView from './views/Header/header.view.js';
import WorkShops from './Pages/Agendas/Workshops.js';
import NavigationView from './views/Navigation/Navigation.view.js';
import SpeakersPage from './Pages/Speakers/Speakers.js';
import CompleteRegistration from './Pages/CompleteRegistration/CompleteRegistration.js';
import ProgramPage from './Pages/Program/Program.page.js';
import SuccessPage, { SuccessPageUpdate } from './Pages/SuccessPage/SuccessPage.js';
import Map from './Pages/Map/Map.js';
import Creathon from './Pages/Creathon/Creathon.js';

function App() {
  return (
    <div className="App">
      <Router>
        <HeaderView />

        <NavigationView />

        <Routes>
          <Route path="/workshops" element={<WorkShops />} />
          <Route path="/speakers" element={<SpeakersPage />} />
          <Route path="/map" element={<Map />} />
          <Route path="/program" element={<ProgramPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/creathon" element={<Creathon />} />
          <Route path="/success-updated" element={<SuccessPageUpdate />} />
          <Route path="/complete-registration" element={<CompleteRegistration />} />
          <Route path="/complete-registration/:id" element={<CompleteRegistration />} />
          <Route path="/" element={<Landing />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

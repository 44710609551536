import './AgendaTabs.scss'
import { NavLink } from 'react-router-dom/'

function AgendaTabs() {
    return (
        <div>
            <div className="title title--agenda">Program</div>

            <div className="tab">
                <NavLink to={'/program'} className="tab__item">Main Program</NavLink>
                <NavLink to={'/workshops'} className="tab__item">Workshops</NavLink>
            </div>
        </div>
    )
}

export default AgendaTabs;
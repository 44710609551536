import './Speaker.scss';

import OksanaImg from '../../assets/imgs/speakers/oksana.png';
import AndyImg from '../../assets/imgs/speakers/andy.png';
import EricImg from '../../assets/imgs/speakers/eric.png';
import AyeshaImg from '../../assets/imgs/speakers/ayesha.png';
// import PaulaImg from '../../assets/imgs/speakers/paula.png';
// import PavloImg from '../../assets/imgs/speakers/pavlo.png';
import Bernardo from '../../assets/imgs/speakers/Bernardo.png';
import Orcun from '../../assets/imgs/speakers/orcun.png';

import Art1 from '../../assets/imgs/art-1.png';
import Art2 from '../../assets/imgs/art-2.png';
import Art3 from '../../assets/imgs/art-3.png';
import Art4 from '../../assets/imgs/art-4.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function SpeakerComponent() {
    const speakers = [
        {
            name: 'OKSANA',
            surname: 'VALENTELIS',
            company: 'Tiktok',
            position: 'Creative director',
            location: 'uk',
            image: OksanaImg,
            art: Art1,
        },
        {
            name: 'ANDY',
            surname: 'CHILDS',
            company: 'META',
            position: 'BUSINESS STRATEGIST, FUTURIST',
            location: 'DE',
            image: AndyImg,
            art: Art2,
        },
        {
            name: 'ERIC',
            surname: 'SHAMLIN',
            company: 'MEDIA.MONKS',
            position: (
                <>
                    EVP. GLOBAL HEAD
                    <br />
                    OF ENTERTAINMENT
                    <br />
                    (2X EMMY WINNER)
                </>
            ),
            location: 'LA',
            image: EricImg,
            art: Art3,
        },
        {
            name: 'AYESHA',
            surname: 'KHAN',
            company: 'NIKE',
            position: 'CREATIVE BRAND ARCHITECT',
            location: 'JP',
            image: AyeshaImg,
            art: Art4,
        },
        {
            name: 'Bernardo',
            surname: 'González',
            company: 'Droga5',
            position: 'Creative Director',
            location: 'US',
            image: Bernardo,
            art: Art2
        },
        {
            name: 'Orcun',
            surname: 'Fistik',
            company: 'Salesforce ',
            position: 'Marketing Solutions Regional Lead ',
            location: 'FI',
            image: Orcun,
            art: Art1,
        },
        // {
        //     name: 'PAVLO',
        //     surname: 'KUZNETSOV',
        //     company: 'Smartly.io',
        //     position: 'SENIOR PRODUCT MANAGER',
        //     location: 'FI',
        //     image: PavloImg,
        //     art: Art1,
        // },
        // {
        //     name: 'PAULA MARIE',
        //     surname: 'KILGARIFF',
        //     company: 'TU DUBLIN',
        //     position: 'INTERNATIONAL LECTURER',
        //     location: 'IR',
        //     image: PaulaImg,
        //     art: Art2,
        // },
    ];
    return (
        <div className='speakers'>
            {
                speakers.map((speaker, index) => {
                    return (
                        <div className="speaker" key={index}>
                            <div className='speaker__top'>
                                <div className='speaker__location'>
                                    {speaker.location}
                                </div>
                                <div className='speaker__content'>
                                    <div className='speaker__company'>
                                        {speaker.company}
                                    </div>
                                    <div className="speaker__image">
                                        <LazyLoadImage src={speaker.art} alt="Art 1" className='speaker__art' />
                                        <LazyLoadImage effect="blur" src={speaker.image} alt="TikTok Speaker" />
                                    </div>
                                </div>
                                <div className='speaker__position'>
                                    {speaker.position}
                                </div>
                            </div>

                            <div className='speaker__name'>
                                {speaker.name}
                                <br />
                                <div className='speaker__name--right'>
                                    {speaker.surname}
                                </div>
                            </div>
                        </div >
                    )
                })
            }
        </div>
    );
}

export default SpeakerComponent;

import './Tickets.scss'

function TicketsView() {
    return (
        <div className='tickets'>
            <div className="ticketsCard">
                <div className="ticketsCard__title">full pass</div>

                <div className="ticketsCard__price">200  <span>GEL</span></div>

                <div className="ticketsCard__description">
                    <div className="ticketsCard__description--item">- Access to talks & panels</div>
                    <div className="ticketsCard__description--item">- Access to recorded videos of the talks</div>
                    <div className="ticketsCard__description--item">- Workshops & networking zone</div>
                    <div className="ticketsCard__description--item">- After party at TES Club</div>
                </div>

                <a href='https://tkt.ge/touchpoint' rel='noreferrer' target='_blank' className="ticketsCard__button ticketsCard__button--red">Get the pass</a>
            </div>
            <div className="ticketsCard">
                <div className="ticketsCard__title">gang pass</div>

                <div className="ticketsCard__price">550  <span>GEL</span></div>

                <div className="ticketsCard__description">
                    <div className="ticketsCard__description--item">- Access to talks & panels</div>
                    <div className="ticketsCard__description--item">- Access to recorded videos of the talks</div>
                    <div className="ticketsCard__description--item">- workshops & networking zone</div>
                    <div className="ticketsCard__description--item">- After party at TES Club</div>
                    <div className="ticketsCard__description--item">- Networking dinner with speakers and partners.</div>
                </div>

                <a href='https://tkt.ge/touchpoint' rel='noreferrer' target='_blank' className="ticketsCard__button ticketsCard__button--purple">Get the pass</a>
            </div>
            <div className="ticketsCard">
                <div className="ticketsCard__title">Student</div>

                <div className="ticketsCard__price">60 <span>GEL</span></div>

                <div className="ticketsCard__description">
                    <div className="ticketsCard__description--item">- Access to talks & panels</div>
                    <div className="ticketsCard__description--item">- Access to recorded videos of the talks</div>
                    <div className="ticketsCard__description--item">- Workshops & networking zone</div>
                    <div className="ticketsCard__description--item">- After party at TES Club</div>
                </div>

                <a href='https://tkt.ge/touchpoint' rel='noreferrer' target='_blank' className="ticketsCard__button ticketsCard__button--yellow">Get the pass</a>
            </div>
        </div>
    )
}

export default TicketsView;
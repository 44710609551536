import './Info.scss';

function InfoComponent({ text, count }) {
    return (
        <div className="info">
            <div className="info__text">{text}</div>
            <div className="info__count">{count}</div>
        </div>
    );
}

export default InfoComponent;

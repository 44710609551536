import AgendaItem from "../../components/AgendaItem/AgendaItem";

import OksanaImg from '../../assets/imgs/speakers/oksana.png';
import AndyImg from '../../assets/imgs/speakers/andy.png';
import EricImg from '../../assets/imgs/speakers/eric.png';
import AyeshaImg from '../../assets/imgs/speakers/ayesha.png';
import Bernardo from '../../assets/imgs/speakers/Bernardo.png';
import Laura from '../../assets/imgs/speakers/Laura.png';
import Sally from '../../assets/imgs/speakers/sally.png';
import Mariam from '../../assets/imgs/speakers/mariam.png';
import Shota from '../../assets/imgs/speakers/shota.png';
import Jino from '../../assets/imgs/speakers/jino.png';
import Menteshashvili from '../../assets/imgs/speakers/menteshashvili.png';
// import PaulaImg from '../../assets/imgs/speakers/paula.png';
// import PavloImg from '../../assets/imgs/speakers/pavlo.png';
import Orcun from '../../assets/imgs/speakers/orcun.png';
import Kinwura from '../../assets/imgs/speakers/kinwura.png';
import Chkhartishvili from '../../assets/imgs/speakers/givichkha.png';
import Jacek from '../../assets/imgs/speakers/jacek.png';
import Adam from '../../assets/imgs/speakers/adam.png';
import Sandro from '../../assets/imgs/speakers/sandro.png';
import Mdivani from '../../assets/imgs/speakers/mdivani.png';
import Cookle from '../../assets/imgs/speakers/cookle.png';
import Dadiani from '../../assets/imgs/speakers/dadiani.png';
// import Avaliani from '../../assets/imgs/speakers/avaliani.png';
import ChavChav from '../../assets/imgs/speakers/chavchav.png';
import Ruso from '../../assets/imgs/speakers/ruso.png';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from "react";
import AgendaTabs from "../../components/AgendaTabs/AgendaTabs";

gsap.registerPlugin(ScrollTrigger);

function ProgramPage() {
    useEffect(() => {
        const boxes = gsap.utils.toArray('.agendaItem');

        boxes.forEach((box, i) => {
            const anim = gsap.fromTo(box, { autoAlpha: 0, y: 50 }, { duration: 1, autoAlpha: 1, y: 0 });
            ScrollTrigger.create({
                trigger: box,
                animation: anim,
                toggleActions: 'play none none none',
                once: true,
            });
        });
    }, []);

    const mainStageAgenda = [
        {
            time: "9:00 - 17:00",
            title: "Registration",
        },
        {
            time: "10:00 - 10:20",
            title: 'Welcome Speech',
        },
        {
            time: "10:20 - 10:40",
            title: "Don't Make Ads, Make TikToks",
            person: "OKSANA VALENTELIS",
            position: "Creative director",
            company: 'Tiktok',
            image: OksanaImg,
        },
        {
            time: "10:40 - 11:00",
            title: "Modern Marketing",
            person: "Jacek Markowski",
            position: "Ads Solution Architect",
            company: 'Google ',
            image: Jacek,
        },
        {
            time: "11:00 - 11:20",
            title: "Cultural Metamorphosis",
            person: "Jino Dollini",
            position: "CEO",
            company: 'Terminal',
            image: Jino,
        },

        {
            time: "11:20 - 11:40",
            title: "The Missing Piece of the Data-driven Marketing",
            person: "Mariam Lelashvili",
            company: 'Optio',
            position: "CPO",
            image: Mariam,
        },
        {
            time: "11:40 - 12:00",
            title: "TBC Digital Bank Game",
            person: "Sally Dadiani",
            position: "Brand Manager at Digital Growth Team",
            company: 'TBC Bank',
            image: Dadiani,
        },
        {
            time: "12:00 - 12:20",
            title: "Online consumer behaviour",
            person: "Nini Chachanidze",
            position: "Chief customer experience officer",
            company: 'Veli.store ',
            image: ChavChav,
        },

        {
            time: "12:20 - 13:00",
            title: "Panel Discussion - Brands of Tomorrow",
            person: "Oksana Valentinis",
            position: "Narrative & Experience Director",
            multi: true,
            images: [
                Shota,
                AyeshaImg,
                Bernardo,
                // Orcun
            ]
        },
        {
            time: "13:00 - 14:00",
            title: "BREAK",
        },
        {
            time: "14:00 - 14:20",
            title: "Game Changer: How Gamification Drives Customer Behavior Design",
            person: "Vakhtang Mdivani",
            position: "Chief Product Officer",
            company: 'Promofy',
            image: Mdivani,
        },
        {
            time: "14:20 - 14:40",
            title: "Beyond payments - how data enables banks to reimagine payments and loyalty services",
            person: "Giorgi Kintsurashvili",
            position: "Head of Digital Banking Ecosystem",
            company: (
                <>Bank of <br /> Georgia</>
            ),
            image: Kinwura,
        },

        {
            time: "14:40 - 15:00",
            title: "5 Marketing Mantras, Case Study from 25 years at Apple",
            person: "David Cockle",
            position: (<>Consultant <br /> (25+ years at Apple)</>),
            company: 'Ex Apple',
            image: Cookle,
        },

        {
            time: "15:00 - 15:20",
            title: "Analog Innovations in the Digital Age: How Coors Light created the world’s first fully sustainable convenience store",
            person: "Bernardo González",
            position: "Creative Director",
            company: 'Droga5',
            image: Bernardo,
        },

        {
            time: "15:20 - 15:40",
            title: "Hack for Good",
            person: "Laura Visco",
            position: "CCO",
            company: 'Grey',
            image: Laura,
        },

        {
            time: "15:40 - 16:00",
            title: "Think Like an Anthropologist",
            person: "Mariam Menteshashvili",
            position: "Insight Analyst",
            company: 'PRS IN VIVO',
            image: Menteshashvili,
        },

        {
            time: "16:00 - 16:20",
            title: "Problems that shape us",
            person: "Sandro Javakhishvili",
            position: "Head of Strategy",
            company: 'JWT Metro',
            image: Sandro,
        },

        {
            time: "16:20 - 16:40",
            title: "Panel Discussion - Harnessing Technology and Media Platforms to Foster Seamless Collaboration between In-House and Agency Teams",
            company: 'mtebi',
            person: "Oksana Valentinis",
            position: "Creative DIRECtor at TIKTOK",
            multi: true,
            images: [
                Adam,
                Dadiani,
                Ruso,
                Sandro,
            ]
        },
        {
            time: "16:40 - 17:00",
            title: "Beyond Borders: Navigating Marketing as a Multidisplinary Creative",
            person: "AYESHA KHAN",
            position: "Narrative & Experience Director",
            company: 'NIKE',
            image: AyeshaImg,
        },

        {
            time: "17:00 - 17:20",
            title: "Create connected and personalised experiences for your customers",
            person: "Orcun Fistik",
            position: "Marketing Solutions Regional Lead",
            company: 'Salesforce',
            image: Orcun,
        },

        {
            time: "17:20 - 17:40",
            title: "From Digital Natives to Virtual Natives; in the age of AI and the Metaverse",
            person: "ERIC SHAMLIN",
            position: (
                <>
                    EVP. GLOBAL HEAD
                    <br />
                    OF ENTERTAINMENT
                    <br />
                    (2X EMMY WINNER)
                </>
            ),
            company: 'MEDIA.MONKS',
            image: EricImg,
        },

        {
            time: "17:40 - 18:00",
            title: "Building brands on mobile",
            person: "Adam Nowakowski",
            position: "Creative Agency Partner",
            company: 'META',
            image: Adam,
        },

        {
            time: "18:00 - 18:20",
            title: "Leveraging Internet Intelligence in data-driven marketing  ",
            person: "Givi Chkhartishvili",
            company: 'Hapttic',
            position: "Project Lead",
            image: Chkhartishvili,
        },

        {
            time: "18:20 - 18:40",
            title: "Constant search of wow ",
            person: "Sally Kerkadze",
            company: 'Leavingstone ',
            position: "Head of Web",
            image: Sally,
        },

        {
            time: "18:40 - 19:00",
            title: "Unlocking AI for Growth",
            person: "Andy Childs",
            position: "BUSINESS STRATEGIST, FUTURIST",
            company: 'META',
            image: AndyImg,
        },

        {
            time: "19:00 - 20:00",
            title: "Networking ",
        },

        {
            time: "20:00 - 00:00",
            title: "AFTERPARTY at TES Club",
        },
    ]
    return (
        <div>
            {/* <div className="title">Program</div> */}
            <AgendaTabs />
            <div className="content container">
                {
                    mainStageAgenda.map((item, index) => {
                        const randomArt = Math.floor(Math.random() * (4 - 1 + 1)) + 1;

                        return (
                            <AgendaItem
                                randomArtClassName={`agendaItem__art-${randomArt}`}
                                key={index}
                                time={item.time}
                                title={item.title}
                                description={item.description}
                                person={item.person}
                                position={item.position}
                                image={item.image}
                                images={item.images}
                                company={item.company}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProgramPage;
import { NavLink } from 'react-router-dom';
import './Navigation.scss';

const NavigationView = () => {
    return (
        <div className="marquee navigation">
            <NavLink to="/speakers" className="marque__item">
                SPEAKERS
            </NavLink>
            <NavLink to="/complete-registration" className="marque__item">
                Registration
            </NavLink>
            <NavLink to="/program" className="marque__item">
                PROGRAM
            </NavLink>
            <NavLink to="/workshops" className="marque__item">
                Workshops
            </NavLink>
            <NavLink to="/map" className="marque__item">
                MAP
            </NavLink>
            <NavLink to="/creathon" className="marque__item">
                CREATHON
            </NavLink>
            <a rel="noreferrer" href="https://tkt.ge/event/348897/touch-point" target='_blank' className="marque__item">
                get pass
            </a>
            {/* <div className="marque__item">
                NIKE
            </div>
            <div className="marque__item">
                Media.Monks
            </div> */}
        </div>
    );
}

export default NavigationView;
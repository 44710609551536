import './AgendaItem.scss';


function AgendaItem({
    time,
    title,
    person,
    position,
    image,
    randomArtClassName,
    images,
    company
}) {
    return (
        <div className={`agendaItem agendaItem__art ${person && !images && `${randomArtClassName} agendaItemImg`}`}>
            <div className="agendaItem__time">
                {time}
            </div>
            <div className={`agendaItem__container ${images && 'agendaItem__container--lg'}`}>
                <div className="agendaItem__title">
                    {title}
                </div>
                {person && (
                    <div className="agendaItem__right">
                        {!images && (
                            <div className="agendaItem__person">
                                <div className="agendaItem__person__name">
                                    {person}
                                </div>
                                <div className="agendaItem__person__position">
                                    {position}
                                    <br />
                                    {company}
                                </div>
                            </div>
                        )}
                        <div className="agendaItem__image">
                            {
                                images && (
                                    <div>
                                        {
                                            images.map((image, index) => {
                                                return (
                                                    <img className='multi-images' key={image} src={image} alt="John Doe" />
                                                );
                                            })
                                        }
                                    </div>
                                )

                            }
                            {
                                !images && <img src={image} alt="John Doe" />
                            }
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default AgendaItem;
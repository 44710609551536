import './cover.scss';

function CoverView() {
    return (
        <div className="cover">
            <div className="cover__text">
                Touch
                <br />
                point
            </div>
        </div>
    )
}

export default CoverView;
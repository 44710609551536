import './Partners.scss';

import Gipa from '../../assets/imgs/partners/gipa.png';
import Buro from '../../assets/imgs/partners/buro.png';
import Lokal from '../../assets/imgs/partners/lokal.png';
import Gau from '../../assets/imgs/partners/gau.png';
import Techchill from '../../assets/imgs/partners/techchill.png';
import Partner59 from '../../assets/imgs/partners/59.png';
import Comm from '../../assets/imgs/partners/comm.png';
import Acag from '../../assets/imgs/partners/acag.png';


function CommunityPartners() {
    return (
        <div className='container'>
            <div className='partner__container'>
                <a href='https://gipa.ge/eng/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Gipa} alt='IBM' />
                </a>
                <a href='https://www.gau.edu.ge/ka/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Gau} alt='IBM' />
                </a>
                <a href='https://latitude59.ee/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Partner59} alt='IBM' />
                </a>
            </div>
            <div className='partner__container'>
                <a href='https://www.techchill.co/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Techchill} alt='IBM' />
                </a>
                <a href='https://commschool.ge/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Comm} alt='IBM' />
                </a>
                <a href='https://startupburo.org/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Buro} alt='IBM' />
                </a>
            </div>
            <div className='partner__container'>
                <a href='https://lokaltbilisi.com/' rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Lokal} alt='IBM' />
                </a>
                <a href="https://terminal.center//" rel="noreferrer" target='_blank' className='partner__item'>
                    <img src="https://touch.ge/files/text/2020-10-19/743.svg" alt='terminal center' />
                </a>

                <a href="https://acag.ge" rel="noreferrer" target='_blank' className='partner__item'>
                    <img src={Acag} alt='Borjomi' />
                </a>
            </div>
        </div>
    );
}

export default CommunityPartners;
// Map component
import './Map.scss';
import MapImage from '../../assets/imgs/map.png';

const Map = () => {
    return (
        <div className='map'>
            <img src={MapImage} alt={'map'} />
        </div>
    )
}

export default Map;
// success page

import React from 'react';
import './SuccessPage.css';

const SuccessPage = () => {
    return (
        <div className='success-page'>
            your ticket successfully registered,
            <br />
            check your inbox please
        </div>
    )
}

export const SuccessPageUpdate = () => {
    return (
        <div className='success-page'>
            Information updated
        </div>
    )
}

export default SuccessPage;
import axios from 'axios'
import './CompleteRegistration.scss'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import Loader from '../../components/Loader/Loader.component';
import { QrReader } from 'react-qr-reader';
import { ViewFinder } from '../../components/ViewFinder.component';

import logo from '../../assets/imgs/logo.svg';
import { useNavigate, useParams } from 'react-router-dom';

const loginValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    full_name: Yup.string().required("Full name is required"),
    phone: Yup.string().required("Phone is required"),
    company: Yup.string().required("Company is required"),
    position: Yup.string().required("Position is required"),
    country: Yup.string().required("Country is required"),
});

const CompleteRegistration = () => {
    const { id } = useParams();

    const navigate = useNavigate()
    const [ticketId, setTicketId] = useState('')
    const [error, setError] = useState('')
    const [formShown, setFormShown] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isScannerOpen, setIsScannerOpen] = useState(false)
    const [ticket, setTicket] = useState(null)
    const [scannerButtonShow, setScannerButtonShow] = useState(false)

    const checkQR = async () => {
        if (!ticketId && !id) {
            setError('Please enter ticket id')
            return
        }

        console.log('checkQR')
        document.body.style.overflow = 'auto'
        setIsLoading(true)

        if ((!ticketId || ticketId === '' || ticketId.length === 0) && !id) {
            setError('Please enter ticket id')
            return
        }

        await axios.get(`https://api.mtebi.com/touchpoint/ticket/${ticketId || id}`)
            .then(res => {
                const { data } = res

                if (data) {
                    // set formik values
                    formik.setFieldValue('email', data.email ? data.email : '')
                    formik.setFieldValue('full_name', data.full_name ? data.full_name : '')
                    formik.setFieldValue('phone', data.phone ? data.phone : '')
                    formik.setFieldValue('company', data.company ? data.company : '')
                    formik.setFieldValue('position', data.position ? data.position : '')
                    formik.setFieldValue('country', data.country ? data.country : '')

                    setTicket(data)
                    setFormShown(true)
                    setError('')
                } else {
                    setError('Ticket not found')
                }
            })
            .catch(err => {
                setError('Ticket not found')
                console.log(err)
            });
        setIsLoading(false)
    }

    const onSubmit = async (values) => {
        setIsLoading(true)
        document.body.style.overflow = 'auto'
        await axios.patch(`https://api.mtebi.com/touchpoint/ticket/${ticketId}`, values)
            .then(res => {
                console.log(res)
                if (ticket.is_completed) {
                    navigate('/success-updated')
                }
                else {
                    navigate('/success')
                }
            })
            .catch(err => {
                console.log(err)
            });
        setIsLoading(false)
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            full_name: "",
            phone: "",
            company: "",
            position: "",
            country: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit,
    });

    const onScan = async (text) => {
        document.body.style.overflow = 'auto'
        console.log(text);
        setIsScannerOpen(false)
        setTicketId(text)
    }

    const options = {
        ViewFinder,
        videoId: 'video',
        scanDelay: 500,
        constraints: {
            facingMode: 'environment',
        },
        facingMode: "environment"
    };

    const videoPreview = {
        width: '200vw',
        height: '100vh',
        top: '50%',
        left: '50%',
        'transform': 'translate(-50%, -50%)',
        position: 'absolute',
    }

    const containerPreview = {
        width: '100%',
        paddingTop: '100vh',
        margin: '0 auto',
        position: 'absolute',
        top: "0",
        left: 0
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setTicketId(id);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                await checkQR();
            }
        };

        fetchData();

        // Optional clean-up function if needed
        return () => {
            // Clean-up logic goes here
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        const isMobile = window.innerWidth < 768;

        isMobile && setScannerButtonShow(true)
    }, [])

    return (
        <div className="cRegistration">
            <h1 className='cRegistration__title'>
                to complete the registration
                <br />
                please check and fill the form
            </h1>

            {isLoading && <Loader />}

            {
                isScannerOpen && (
                    <div>
                        <div className='scannerHeader'>
                            <a href="https://touch-tkt.web.app/" className='scannerHeader__logo'>
                                <img src={logo} alt="touch logo" className="scannerHeader__logo-image" />
                            </a>
                        </div>

                        <QrReader
                            videoContainerStyle={containerPreview}
                            videoStyle={videoPreview}
                            {...options}
                            onResult={(result, error) => {
                                if (!!result) {
                                    if (!isLoading) {
                                        // setLoader(true)
                                        onScan(result?.text)
                                    }
                                }
                            }}
                        />
                        <div className='message'>
                            Please align the QR code located on the machine's sticker with the viewfinder above.
                        </div>
                    </div>
                )
            }

            {
                !isScannerOpen && !isLoading && !formShown && (
                    <div className="cRegistration__form">
                        <div className='ticketScan'>
                            <div className="cRegistration__form-control ticketScan-input">
                                <label htmlFor="name">ticket id</label>
                                <input type="text" id="name" value={ticketId} onChange={(e) => setTicketId(e.target.value)} />
                                {scannerButtonShow && (
                                    <div className='ticketScan-btn' onClick={() => {
                                        setIsScannerOpen(true)
                                        document.body.style.overflow = 'hidden'
                                        window.scrollTo(0, 0)
                                    }}>scan qr</div>
                                )}
                            </div>

                            {error && <div className='ticketScan-error'>{error}</div>}
                            <br />

                            <button onClick={checkQR} className='cRegistration-button'>Check your ticket</button>
                        </div>
                    </div>
                )
            }

            {
                !isScannerOpen && !isLoading && formShown && (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="cRegistration__form">
                            <div className="cRegistration__form-control">
                                <label htmlFor="name">Full Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name='full_name'
                                    value={formik.values.full_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>

                            <div className="cRegistration__form-control">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>

                            <div className="cRegistration__form-control">
                                <label htmlFor="phone">Phone</label>
                                <input
                                    type="string"
                                    id="phone"
                                    name='phone'
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>

                            <div className="cRegistration__form-control">
                                <label htmlFor="company">Company</label>
                                <input
                                    type="text"
                                    id="company"
                                    name='company'
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>

                            <div className="cRegistration__form-control">
                                <label htmlFor="position">Position</label>
                                <input
                                    type="text"
                                    id="position"
                                    name='position'
                                    value={formik.values.position}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>

                            <div className="cRegistration__form-control">
                                <label htmlFor="country">Country</label>
                                <input
                                    type="text"
                                    id="country"
                                    name='country'
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>

                            {error && <div className='ticketScan-error'>{error}</div>}

                            <button type="submit" className='cRegistration-button'>
                                {
                                    ticket.is_completed ? 'Update' : 'Complete registration'
                                }
                            </button>
                        </div>
                    </form >
                )
            }
        </div >
    );
}

export default CompleteRegistration;